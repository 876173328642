<template>
	<div class="settle">
		<div class="center_context center">服务机构申请授权</div>
		<div class="content">
			<div class="form">
				<div class="title">
					<div class="spikel"></div>
					申请授权
				</div>
				<div class="formBox">
					<el-form ref="form" :model="form" label-width="120px" :rules="rules">
						<el-form-item label="机构名称：" prop="title">
							<el-input v-model="form.title" placeholder="请输入机构名称"></el-input>
						</el-form-item>
						<el-form-item label="行业名称：" prop="industry">
							<el-select v-model="form.industry" placeholder="请选择" value-key="id">
								<el-option v-for="(item, index) in options" :key="index" :label="item.title" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="联系人姓名：" prop="name">
							<el-input v-model="form.name" placeholder="请输入联系人姓名..."></el-input>
						</el-form-item>
						<el-form-item label="联系人电话：" prop="tel">
							<el-input v-model="form.tel" placeholder="请输入联系人电话..."></el-input>
						</el-form-item>
						<el-form-item label="简介说明：" prop="content">
							<el-input placeholder="请输入简介..." v-model="form.content" resize="none" :rows="7" type="textarea"></el-input>
						</el-form-item>
						<!-- <el-form-item label="选择器：">
              <el-select v-model="value" placeholder="请选择">
                <el-option label="选我啊" :value="1"> </el-option>
              </el-select>
            </el-form-item> -->
					</el-form>
				</div>
				<div class="form-bottoms">
					<div class="form-bottom"></div>
					<div class="bottom-button">
						<div class="select-tiaokuan center">
							<el-checkbox v-model="radio"></el-checkbox>
							<!-- <el-radio v-model="radio"></el-radio> -->
							<div class="read_me" @click="dialogVisible = true">
								风险提示与免责声明
							</div>
						</div>
						<el-button type="primary" @click="onSubmit">提交申请</el-button>
					</div>
				</div>
			</div>
			<el-dialog title="免责声明" :visible.sync="dialogVisible" width="1400px">
				<template #title>
					<div class="align-center font-three">
						<div class="spikel"></div>
						<div>免责声明</div>
					</div>
				</template>
				<div v-html="context" class="form_MZ"></div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		mapActions,
		mapGetters
	} from "vuex";
	export default {
		async created() {
			const {
				data
			} = await this.$api({
				c: "index",
				a: "trade",
			});
			//console.log(data);

			this.options = data.res;
		},
		data() {
			var checkPhone = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("手机号不能为空"));
				} else {
					const reg = /^1[1|3|4|5|7|8|9][0-9]\d{8}$/;
					//console.log(reg.test(value));
					if (reg.test(value)) {
						callback();
					} else {
						return callback(new Error("请输入正确的手机号"));
					}
				}
			};

			return {
				options: [],

				context: `尊敬的江苏文交所文化IP交易中心用户：

        江苏文交所文化IP交易中心(以下统称为“交易中心"或""交易平台")是指江苏省文化产权交易所提供的IP权科金证和相关知识产权授权交易的平台，旨在实现P作品权利验证、安全交易和其他相关服务的功能。交易平台包括网址为http:/ /www.XXXXXXXXXXX.com的网站及该域名下所有相关URLs。
        您在访问或使用交易中心时，需要注意以下免责事项:
        一、您通过交易中心进行P交易或者使用交易中心提供的其他服务或信息之前，应向交易中心提出申请并签署相关协议。如您未进行前述协议签订，则您在交易中心上参加P交易或者使用交易中心提供的其他服务或信息时,所产生的一切现有的或未来可能发生的风险或损失，与交易中心无关，由您自行承担。
        二、您在申请注册和接受服务的过程中，应根据交易中心的要求如实提供完整、真实、准确和最新的用户资料;并保证用户账户信息安全，只要使用了正确的用户账号信息，无论是否您本人登陆，均视为已经得到本人授权，您应自行对自己账户所进行的一切活动引起的任何损失或损害承担全部责任。
        三、交易中心有权根据实际需求随时对相关协议条款或规则等文件进行修改和更新，并及时告知您，修改后的文件一经告知即有效代替原来的文件内容。如您对变更后的文件条款存在任何异议，可随时停止使用交易平台，如选择继续使用，则视为您完全接受本文件条款的变更。
        四、您须保证在交易中心参与的交易服务的资金来源合法，否则由此引起的一切法律后果和责任均由您自行承担。
        五、交易中心自身并不介入任何iP授权交易，亦不对任何交易进行担保，因此您通过交易中心授权他人使用的P权利可能被其他交易参与人或第三人提起权属争议或其他投诉﹔您通过交易中心获得授权的IP权利亦可能被其他交易参与人或第三人提起侵权投诉。一切因IP作品的权属争议而产生的责任和损失，一概与交易平台无关，由您自;行承担。
        六、因信息网络而发生的正常的设备维护，信息网络连接故障，电脑、通讯或其他系统的故障，电力故障,战争，罢工，劳动争议，暴乱，起义，骚乱，生产力或生产资料不足，火灾，洪水，风暴，爆炸，政府行为，司法行政机关的命令等不可抗力因素或第三方原因带来的风险与损失，交易中心无须承担责任。
        七、您在交易中心进行IP授权交易时，可能面临的包括但不限于交易价格风险、市场风险以及作品差异风险等风险，均可能给您带来风险及损失，上述风险及由此造成的损失，一概与交易中心无关，须由您自行承担。
        八、交易中心不保证向用户提供的外部链接的准确性和完整性，该外部链接指向的不由交易中心奕际控制的狂何网页上的内容，交易中心对其合法性亦概不负责，亦不承担任何法律责任，网页内所有内容亦不表明交易中心;之观点或意见。
        九、凡以任何方式访问、登录交易中心或直接、间接接受交易中心所提供的服务者，均视为自愿接受交易中心免责声明的约束。
        十、江苏省文化产权交易所对交易中心兔责声明享有最终解释权。

        联系我们:_025-69610381.0512-XXXXXXXX
`,
				radio: false,
				dialogVisible: false,
				value: "",
				form: {
					name: "",
					tel: "",
					title: "",
					industry: "",
					power: "",
					email: "",
					// type: "",
					content: "",
				},
				rules: {
					title: [{
						required: true,
						message: "请输入机构名称",
						trigger: "blur"
					}],

					name: [{
						required: true,
						message: "请输入联系人姓名",
						trigger: "blur"
					}, ],
					industry: [{
						required: true,
						message: "请选择行业类别",
						trigger: "blur"
					}, ],
					tel: [{
							required: true,
							message: "请输入联系人电话",
							trigger: "blur"
						},
						{
							validator: checkPhone
						},
					],
					content: [{
						required: true,
						message: "请输入简介说明",
						trigger: "blur"
					}, ],
				},
				uinfo: {
					token: '',
					nickname: '',
					phone: '',
					userinfoL: [],
				},
			};
		},
		computed: {
			...mapGetters(["userinfo"]),
		},
		methods: {
			...mapActions(["Setuserinfo"]),
			onSubmit() {
				if (this.userinfo.token.length <= 0) {
					this.$message({
						type: "warning",
						message: "请先登录",
					});
					var datas = this.uinfo;
					this.Setuserinfo(datas);
				}

				this.$refs.form.validate(async (valid) => {
					if (valid) {
						if (!this.radio) {
							this.$message.error("请先阅读风险提示和免责声明");
							return false;
						}
						const data = await this.$api({
							c: "IPjy",
							a: "jigou_add",
							token: this.userinfo.token,
							title: this.form.title,
							user_name: this.form.name,
							user_phone: this.form.tel,
							jigou_cate_id: this.form.industry,
							content: this.form.content,
							type: 4,
						});
						if (data.errorCode == 200) {
							this.$message({
								type: "success",
								message: "提交成功",
							});
							this.$router.push("/index/service");
						} else if (data.errorCode == 403) {
							var datas = this.uinfo;
							this.Setuserinfo(datas);
							this.$message({
								type: "success",
								message: data.description,
							});

						} else {
							this.$message({
								type: "success",
								message: data.description,
							});
						}
					} else {
						return false;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.settle {
		height: 100%;
	}

	.center_context {
		height: 250px;
		background: url("../../assets/service/service.png");

		background-size: 100% 100%;
		color: #ffffff;
		font-weight: 900;
		font-size: 40px;
	}

	.content {
		background: rgb(248, 248, 248);
		height: 100%;

		.form {
			transform: translateY(-20px);
			width: 1200px;
			background: #ffffff;
			margin: 0 auto;
		}
	}

	.title {
		display: flex;
		align-items: center;
		padding: 10px 30px;
		border-bottom: 1px solid #dfdfdf;
	}

	.spikel {
		width: 4px;
		height: 20px;
		margin-right: 14px;
		background: rgb(0, 118, 254);
	}

	.formBox {
		padding: 77px 0px 86px 86px;
		width: 600px;
	}

	.form-bottom {
		//   margin-top: 86px;
		border-bottom: 1px solid #dfdfdf;
	}

	.bottom-button::v-deep {
		margin-top: 60px;
		padding-left: 100px;

		.el-button {
			font-size: 10px;
			padding: 13px 60px;
			border-radius: 0px;
			background: rgb(0, 118, 254);
		}
	}

	.form-bottoms {
		padding: 0px 0px 100px 83px;
	}

	.form_MZ {
		white-space: pre-wrap;
		padding: 0 45px;
		line-height: 25px;
	}

	.select-tiaokuan {
		justify-content: flex-start;
		margin-bottom: 20px;
	}

	.read_me {
		color: rgb(0, 118, 254);
		cursor: pointer;
		margin-left: 10px;
		// transform: translateX(-30px) translateY(-1px);
	}
</style>
